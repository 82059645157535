<template>
  <div>
    <v-dialog v-model="dialogFeedback" persistent max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">
            {{
              user.configurations && user.configurations.feedback && user.configurations.feedback.feedback_weekday
                ? str['update_feedback_day']
                : str['update_feedback_date']
            }}
          </span>
        </v-card-title>
        <v-card-text>
          <v-menu
            v-if="
              (user && user.configurations && user.configurations.feedback && user.configurations.feedback.feedback_day) ||
              (dialogFeedbackType && dialogFeedbackType.type === 'day_select')
            "
            v-model="datepickerFeedbackDayMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template #activator="{ on, attrs }">
              <v-text-field v-model="feedbackDay" :label="str['feedback_date']" readonly v-bind="attrs" v-on="on" />
            </template>
            <v-date-picker v-model="feedbackDay" style="margin: 0" :locale="datepickerLanguage" @change="changeFeedbackDay()" />
          </v-menu>
          <v-autocomplete
            v-if="
              ((user && user.configurations && user.configurations.feedback && user.configurations.feedback.feedback_weekday) ||
                (dialogFeedbackType && dialogFeedbackType.type === 'week_day_select')) &&
              feedbackWeekdays &&
              feedbackWeekdays.length > 1
            "
            v-model="feedbackWeekday"
            :label="str['feedback_day']"
            item-text="label"
            item-value="value"
            :items="feedbackWeekdays"
            :no-data-text="str['no_data']"
            :error="feedbackInputsFailed"
            @change="changeFeedbackWeekday()"
          />
          <v-autocomplete
            v-if="
              ((user && user.configurations && user.configurations.feedback && user.configurations.feedback.feedback_weekday) ||
                (dialogFeedbackType && dialogFeedbackType.type === 'week_day_select')) &&
              feedbackFrequencies &&
              feedbackFrequencies.length > 1
            "
            v-model="feedbackFrequency"
            :label="str['feedback_frequency']"
            item-text="label"
            item-value="value"
            :items="feedbackFrequencies"
            :no-data-text="str['no_data']"
            :error="feedbackInputsFailed"
            @change="changeFeedbackFrequency()"
          />
          <v-autocomplete
            v-if="dialogFeedbackType && dialogFeedbackType.type === 'year_week_select'"
            v-model="feedbackWeek"
            :label="str['week']"
            item-text="label"
            item-value="value"
            :items="feedbackWeeks"
            :no-data-text="str['no_data']"
            :error="feedbackInputsFailed"
            @change="changeFeedbackWeek()"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="default" @click="closeFeedbackDialog()">
            {{ str['cancel'] }}
          </v-btn>
          <v-btn color="success" @click="confirmFeedbackDialog()">
            {{ str['confirm'] }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Api from '@/services/Api'
import Utils from '@/services/Utils'

export default {
  name: 'ClientFeedback',
  props: {
    client: Object,
    mountedCallback: Function,
    destroyCallback: Function,
  },
  data() {
    return {
      str: window.strings,
      datepickerLanguage: window.datepickerLanguage,
      user: Utils.getUser(),
      dialogFeedback: false,
      dialogFeedbackType: null,
      feedbackDay: null,
      feedbackWeekday: null,
      feedbackFrequency: null,
      feedbackWeek: null,
      feedbackWeekdays: Utils.getWeekdays(),
      feedbackFrequencies: Utils.getFeedbackFrequencies(),
      feedbackWeeks: Utils.getFeedbackWeeks(),
      feedbackInputsFailed: false,
      datepickerFeedbackDayMenu: null,
    }
  },
  mounted: function () {
    if (this.mountedCallback) {
      this.mountedCallback()
    }
  },
  methods: {
    getClientFeedbackDay: function (day) {
      if (
        this.user &&
        this.user.configurations &&
        this.user.configurations.feedback &&
        this.user.configurations.feedback.feedback_weekday &&
        day.indexOf('#') > -1
      ) {
        const splitDay = day.split('#')
        const dayToCompare = splitDay[1]
        const dayItem = this.feedbackWeekdays.find(function (item) {
          return item.value === dayToCompare
        })
        const frequencyToCompare = splitDay[2]
        const frequencyItem = this.feedbackFrequencies.find(function (item) {
          return item.value === frequencyToCompare
        })
        let feedbackDayLabel = ''
        if (dayItem && this.feedbackWeekdays.length > 1) {
          feedbackDayLabel += dayItem.label
        }
        if (frequencyItem && this.feedbackFrequencies.length > 1) {
          feedbackDayLabel += (feedbackDayLabel !== '' ? ' - ' : '') + frequencyItem.label
        }
        return feedbackDayLabel
      }
      return day
    },
    getClientFeedbackTypeValue: function (type) {
      let str = ''
      if (
        this.user &&
        this.user.configurations &&
        this.user.configurations.feedback &&
        this.user.configurations.feedback.tabs &&
        this.user.configurations.feedback.save_dates_json
      ) {
        let fbDay = this.client.feedback_day
        if (typeof fbDay === 'string') {
          try {
            fbDay = JSON.parse(fbDay)
          } catch (error) {
            fbDay = {}
          }
        }
        if (!fbDay[type.id]) {
          fbDay[type.id] = ''
        }
        switch (type.type) {
          case 'day_select':
            str = fbDay[type.id]
            break

          case 'week_day_select': {
            if (fbDay[type.id].indexOf('#') > -1) {
              const splitDay = fbDay[type.id].split('#')
              const weekDay = this.feedbackWeekdays.find((t) => splitDay[1] === t.value)
              const freq = this.feedbackFrequencies.find((t) => splitDay[2] === t.value)

              if (weekDay) {
                str = weekDay.label + ' - '
              }
              if (freq) {
                str += freq.label
              }
            }
            break
          }

          case 'year_week_select': {
            const week = this.feedbackWeeks.find((t) => fbDay[type.id] === t.value)
            if (week) {
              str = week.label
            }
            break
          }
        }
      }
      return str
    },
    openFeedbackDialog: function (type) {
      this.dialogFeedback = true
      this.dialogFeedbackType = type
      this.feedbackDay = ''
      this.feedbackWeekday = ''
      this.feedbackFrequency = ''
      this.feedbackWeek = ''
      const feedbackField =
        this.user && this.user.configurations && this.user.configurations.feedback && this.user.configurations.feedback.save_feedback_aux
          ? 'feedback_aux'
          : 'feedback_day'

      if (
        this.user &&
        this.user.configurations &&
        this.user.configurations.feedback &&
        this.user.configurations.feedback.tabs &&
        this.user.configurations.feedback.save_dates_json &&
        this.client[feedbackField] &&
        typeof this.client[feedbackField] === 'string'
      ) {
        try {
          this.client.setValue(feedbackField, JSON.parse(this.client[feedbackField]))
        } catch (error) {
          this.client.setValue(feedbackField, {})
        }
      }

      if (this.client[feedbackField] !== window.strings['n_a']) {
        if (
          this.user &&
          this.user.configurations &&
          this.user.configurations.feedback &&
          this.user.configurations.feedback.tabs &&
          this.user.configurations.feedback.save_dates_json
        ) {
          if (!this.client[feedbackField][type.id]) {
            this.client.setValue(
              {
                key: feedbackField,
                subkey: type.id,
              },
              '',
            )
          }
          switch (type.type) {
            case 'day_select':
              this.feedbackDay = this.client[feedbackField][type.id]
              break
            case 'week_day_select':
              if (this.client[feedbackField][type.id].indexOf('#') > -1) {
                const splitDay = this.client[feedbackField][type.id].split('#')
                this.feedbackWeekday = splitDay[1]
                this.feedbackFrequency = splitDay[2]
              }
              break
            case 'year_week_select':
              this.feedbackWeek = this.client[feedbackField][type.id]
              break
          }
          return true
        }

        if (
          this.user &&
          this.user.configurations &&
          this.user.configurations.feedback &&
          this.user.configurations.feedback.feedback_weekday &&
          this.client[feedbackField].indexOf('#') > -1
        ) {
          const splitDay = this.client[feedbackField].split('#')
          this.feedbackWeekday = splitDay[1]
          this.feedbackFrequency = splitDay[2]
          return true
        }

        this.feedbackDay = this.client[feedbackField]
      }

      if (
        this.user &&
        this.user.configurations &&
        this.user.configurations.feedback &&
        this.user.configurations.feedback.feedback_weekday
      ) {
        if (!this.feedbackWeekday && this.feedbackWeekdays && this.feedbackWeekdays.length === 1) {
          this.feedbackWeekday = this.feedbackWeekdays[0].value
        }
        if (!this.feedbackFrequency && this.feedbackFrequencies && this.feedbackFrequencies.length === 1) {
          this.feedbackFrequency = this.feedbackFrequencies[0].value
        }
      }
    },
    closeFeedbackDialog: function () {
      this.dialogFeedback = false
      this.dialogFeedbackType = null
      if (this.destroyCallback) {
        this.destroyCallback()
      }
    },
    changeFeedbackDay: function () {
      this.feedbackWeekday = ''
      this.feedbackFrequency = ''
      this.feedbackWeek = ''
      this.datepickerFeedbackDayMenu = false
    },
    changeFeedbackWeekday: function () {
      this.feedbackDay = ''
    },
    changeFeedbackFrequency: function () {
      this.feedbackDay = ''
    },
    changeFeedbackWeek: function () {
      this.feedbackDay = ''
    },
    confirmFeedbackDialog: function () {
      const self = this
      const data = {
        id: this.client.id,
        feedback_day: this.feedbackDay,
        log_source: 'MANUAL-DETAILS',
      }

      if (
        this.user &&
        this.user.configurations &&
        this.user.configurations.feedback &&
        this.user.configurations.feedback.feedback_weekday &&
        (!this.user.configurations.feedback.tabs || !this.user.configurations.feedback.save_dates_json) &&
        !this.feedbackDay
      ) {
        this.feedbackInputsFailed = false
        if (!this.feedbackWeekday || !this.feedbackFrequency) {
          this.feedbackInputsFailed = true
          return false
        }
        data.feedback_day = '#' + this.feedbackWeekday
        data.feedback_day += '#' + this.feedbackFrequency
      }

      if (
        this.user &&
        this.user.configurations &&
        this.user.configurations.feedback &&
        this.user.configurations.feedback.tabs &&
        this.user.configurations.feedback.save_dates_json &&
        this.dialogFeedbackType
      ) {
        data.feedback_day = this.client.feedback_day

        if (typeof data.feedback_day === 'string') {
          try {
            data.feedback_day = JSON.parse(data.feedback_day)
          } catch (error) {
            data.feedback_day = {}
          }
        }

        let value = ''
        switch (this.dialogFeedbackType.type) {
          case 'day_select':
            value = this.feedbackDay
            break
          case 'week_day_select':
            this.feedbackInputsFailed = false
            if (!this.feedbackWeekday || !this.feedbackFrequency) {
              this.feedbackInputsFailed = true
              return false
            }
            value = '#' + this.feedbackWeekday
            value += '#' + this.feedbackFrequency
            break
          case 'year_week_select':
            this.feedbackInputsFailed = false
            if (!this.feedbackWeek) {
              this.feedbackInputsFailed = true
              return false
            }
            value = this.feedbackWeek
            break
        }

        data.feedback_day[this.dialogFeedbackType.id] = value
        data.feedback_day = JSON.stringify(data.feedback_day)
      }

      if (
        this.user &&
        this.user.configurations &&
        this.user.configurations.feedback &&
        this.user.configurations.feedback.save_feedback_aux
      ) {
        data.feedback_aux = data.feedback_day
        delete data.feedback_day
      }

      this.closeFeedbackDialog()

      Api.updateUser(data, function (response) {
        if (response.success) {
          self.client.setValue('feedback_day', response.data.feedback_day)
          self.client.setValue('feedback_aux', response.data.feedback_aux)
          self.updatePhysicalEvaluation()
          Utils.setStorage('client', self.client)
          self.client.refresh('details')
        } else {
          self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
        }
      })
    },
    updatePhysicalEvaluation: function () {
      const self = this
      Api.getPhysicalEvaluationToUpdateWithFeedbackUpdated(
        {
          id: this.client.dbId,
          fields: ['id'],
        },
        function (response) {
          if (response.success && response.data && response.data[0]) {
            Api.editPhysicalEvaluation(
              {
                client_id: self.client.dbId,
                id: response.data[0].id,
                date_next: self.client.feedback_day,
              },
              function (response) {
                if (response.success) {
                  self.client.refresh('physical_evaluation')
                }
              },
            )
          }
        },
      )
    },
  },
}
</script>
